import React, {useEffect, useState, useCallback, useRef} from 'react'
import {useSelector} from 'react-redux'
import _ from 'lodash'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {
    Modal,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    Button,
    Tooltip,
    CircularProgress,
    Typography,
    Switch,
} from '@material-ui/core'
import {CloudUploadRounded} from '@material-ui/icons'
import {PlayerPreview} from './PlayerPreview'
import importImg from '../../ressources/images/importImg.png'
import placeHolderImg from '../../ressources/images/placeholder_story_1.jpg'
import AlertSimple from './AlertSimple'
import {BASE_URL} from '../../utils/Api/constants'
import Resizer from 'react-image-file-resizer'

export default function StoryFormModal({isOpen, handleModalClose, handleUpdate}) {
    const token = useSelector((state) => state.users.token)
    const currentAccount = useSelector((state) => state.accounts.current)
    const accounts = useSelector((state) => state.accounts.accounts)
    const currentUser = useSelector((state) => state.users.current)
    const classes = useStyles()
    const [title, setTitle] = useState(undefined)
    const [roles, setRole] = useState(undefined)
    const [nickname, setNickname] = useState(undefined)
    const [imgUrl, setImgUrl] = useState(importImg)
    const [soundUrl, setSoundUrl] = useState(undefined)
    const [soundId, setSoundId] = useState(undefined)
    const [imgId, setImgId] = useState(undefined)
    const [storyId, setStoryId] = useState(undefined)
    const [audioFile, setAudioFile] = useState(undefined)
    const [formComplete, setFormComplete] = useState(false)
    const [pendingImg, setPendingImg] = useState(undefined)
    const [pendingSound, setPendingSound] = useState(false)
    const [soundError, setSoundError] = useState(false)
    const [imgError, setImgError] = useState(false)
    const [membershipId, setMembershipId] = useState(undefined)
    const [rolesList, setRolesList] = useState(undefined)
    const [isPending, setIsPending] = useState(false)
    const [chosenStoryType, setChosenStoryType] = useState('unite')
    const soundFileUploader = useRef(null)
    const imgUploader = useRef(null)
    const users = useSelector((state) => state.users)

    const resetForm = () => {
        setTitle(undefined)
        setRole(undefined)
        setNickname(undefined)
        setImgUrl(importImg)
        setSoundUrl(undefined)
        setSoundId(undefined)
        setImgId(undefined)
        setStoryId(undefined)
        setAudioFile(undefined)
        setFormComplete(false)
        setPendingImg(undefined)
        setPendingSound(false)
        setSoundError(false)
        setMembershipId(undefined)
        setIsPending(false)
    }

    const onClose = () => {
        resetForm()
        handleModalClose()
    }

    const closeSoundAlert = () => {
        setAudioFile(undefined)
        setPendingSound(undefined)
        setSoundUrl(undefined)
        setSoundError(false)
    }

    const closeImgAlert = () => {
        setImgUrl(importImg)
        setImgId(undefined)
        setPendingImg(undefined)
        setImgError(false)
    }
    const getRoles = useCallback(async () => {
        const rawRoles = await fetch(`${BASE_URL}/api/graphql`, {
            method: 'POST',
            body: JSON.stringify({
                query: `{ 
                labels (key: "ROLE", i18n:"fr_FR") {
                    edges { 
                        node { 
                            id 
                            value 
                        } 
                    } 
                } 
            }`,
            }),
            headers: new Headers({
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
            }),
        }).then((res) => res.json())

        setRolesList(_.map(rawRoles.data.labels.edges, (item) => item.node.value))
    }, [token])

    const handleClickSound = (event) => {
        soundFileUploader.current.click()
    }
    const handleClickImg = (event) => {
        imgUploader.current.click()
    }
    const handleChangeSound = async (event) => {
        const soundUploaded = event.target.files[0]
        setPendingSound(true)
        setAudioFile(soundUploaded)
        let formData = new FormData()
        formData.append('file', soundUploaded)
        return uploadAudio(formData)
    }
    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                400,
                400,
                'JPEG',
                100,
                0,
                (file) => {
                    resolve(file)
                },
                'file'
            )
        })

    const handleChangeImg = async (event) => {
        setPendingImg(true)
        const imgUploaded = event?.target?.files[0]
        let imgResized = await resizeFile(imgUploaded)
        let formData = new FormData()
        formData.append('file', imgResized)
        return uploadImg(formData)
    }

    const uploadAudio = async (formData) => {
        const res = await fetch(`${BASE_URL}/api/media_objects`, {
            crossDomain: true,
            method: 'POST',
            headers: new Headers({
                Authorization: 'Bearer ' + token,
            }),
            body: formData,
        })
            .then((response) => {
                return response.json()
            })
            .catch((error) => {
                console.log('error', error)
            })
        try {
            setSoundUrl(res.contentUrl)
            console.log('res', res)
            setSoundId(res['@id'].slice(1))
        } catch (error) {
            console.log(error)
            setSoundError(true)
        }
        setPendingSound(false)
    }

    const uploadImg = async (formData) => {
        const res = await fetch(`${BASE_URL}/api/media_objects`, {
            crossDomain: true,
            method: 'POST',
            headers: new Headers({
                Authorization: 'Bearer ' + token,
            }),
            body: formData,
        })
            .then((response) => {
                return response.json()
            })
            .catch((error) => {
                console.log('error', error)
            })
        try {
            setImgUrl(res.contentUrl)
            setImgId(res['@id'].slice(1))
            setPendingImg(false)
            return res['@id'].slice(1)
        } catch (error) {
            console.log(error)
            setImgError(true)
        }
    }

    const createStory = async () => {
        setIsPending(true)
        let placeholderImgId = 0;
        // Add default picture cover in case user didn't put one
        if (!imgId) {
            const imgBlob = await fetch(placeHolderImg).then(res => {
                return res.blob()
            })
            const imgFile = new File([imgBlob], 'placeholder.jpg')
            let formData = new FormData()
            formData.append("file", imgFile)
            placeholderImgId = await uploadImg(formData)
        }

        let storyToPost = {
            title: title,
            picture: imgId || placeholderImgId,
            sound: soundId,
            duration: 72000,
            multiplexing: [{media: soundId}],
            role: roles,
            pseudo: nickname,
            type: currentUser.type === 'publisher' && chosenStoryType === 'unite' ? 'rocker' : null,
            status: currentUser.type === 'publisher' ? 'draft' : 'published',
        }
        const res = await fetch(`${BASE_URL}/api/stories`, {
            crossDomain: true,
            method: 'POST',
            headers: new Headers({
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify(storyToPost),
        })
            .then((response) => {
                return response.json()
            })
            .catch((error) => {
                console.log('error', error)
            })
        setStoryId(res['@id'].slice(1))
    }

    const createLibrary = useCallback(async () => {
        let libdata = {
            story: storyId,
            membership: membershipId,
            isTransfered: false,
            isActive: true,
        }
        await fetch(`${BASE_URL}/api/libraries`, {
            method: 'POST',
            headers: new Headers({
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify(libdata),
        })
            .then((response) => {
                return response.json()
            })
            .catch((error) => {
                console.log('error', error)
            })
        setIsPending(false)
        handleUpdate()
        handleModalClose()
    }, [storyId, membershipId, token, handleUpdate, handleModalClose])

    const getMembershipId = useCallback(
        async (userId, accountId) => {
            const res = await fetch(`${BASE_URL}/api/graphql`, {
                method: 'POST',
                body: JSON.stringify({
                    query: `query getMembership { 
                    memberships (user_id:  ${userId}, account_id: ${accountId})  {
                      edges {
                        node {
                          id
                          role
                          pseudo
                          isAdmin
                          user {
                            id
                            type
                            lastName
                            firstName
                            picture {
                              contentUrl
                            }
                          }
                        }
                      }
                    }
                 }`,
                }),
                headers: new Headers({
                    Authorization: 'Bearer ' + users.token,
                    'Content-Type': 'application/json',
                }),
            }).then((response) => response.json())
            setMembershipId(res.data.memberships.edges[0].node.id)
            return res
        },
        [users]
    )

    useEffect(() => {
        if (currentUser && currentAccount && accounts) {
            getMembershipId(currentUser.id.split('/')[3], currentAccount.split('/')[3])
        }
    }, [currentUser, currentAccount, accounts, getMembershipId])

    useEffect(() => {
        if (title && roles && soundId) {
            setFormComplete(true)
        }
    }, [title, roles, soundId])

    useEffect(() => {
        if (storyId && membershipId) {
            createLibrary()
        }
    }, [storyId, membershipId, createLibrary])

    useEffect(() => {
        getRoles()
    }, [getRoles])

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            className={classes.root}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className={classes.contents}>
                <div className={classes.titleSection}>
                    <h2>Import d'une histoire</h2>
                </div>
                {isPending ? (
                    <CircularProgress className={classes.circularProgress}/>
                ) : (
                    <>
                        <div className={classes.formSection}>
                            <div className={classes.inputSection}>
                                <TextField
                                    variant="standard"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="title"
                                    label="Titre"
                                    name="title"
                                    value={title}
                                    autoFocus
                                    onChange={(e) => setTitle(e.target.value)}
                                />

                                <FormControl className={classes.select}>
                                    <InputLabel id="demo-simple-select-label" value={roles}>
                                        Rôle *
                                    </InputLabel>
                                    <Select id="role" value={roles} onChange={(e) => setRole(e.target.value)}>
                                        {rolesList &&
                                            rolesList.map((role) => (
                                                <MenuItem value={role} key={role}>
                                                    {role}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>

                                <TextField
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    value={nickname}
                                    name="nickname"
                                    label={currentUser?.type === 'publisher' ? "Éditeur" : "Surnom (optionnel)"}
                                    id="nickname"
                                    onChange={(e) => setNickname(e.target.value)}
                                />
                                {currentUser?.type === 'publisher' && (
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Typography>Pack</Typography>
                                        <Switch
                                            classes={{
                                                track: classes.switchTrack,
                                                switchBase: classes.switchBase,
                                            }}
                                            color="primary"
                                            defaultChecked={chosenStoryType === 'unite'}
                                            value={chosenStoryType}
                                            onChange={() =>
                                                chosenStoryType === 'unite'
                                                    ? setChosenStoryType('inPack')
                                                    : setChosenStoryType('unite')
                                            }
                                        />
                                        <Typography>À l'unité / Playlists</Typography>
                                    </div>
                                )}
                            </div>
                            <div className={classes.rightSection}>
                                {pendingImg ? (
                                    <div className={classes.imgLoader}>
                                        <CircularProgress/>
                                    </div>
                                ) : (
                                    <Tooltip title="Cliquez pour importer votre image au format .jpg">
                                        <div
                                            onClick={handleClickImg}
                                            className={classes.imgSection}
                                            style={{
                                                backgroundImage: `url(${imgUrl.replace('http://', 'https://')})`,
                                                backgroundSize: 'cover',
                                            }}
                                        >
                                            <input
                                                type="file"
                                                style={{display: 'none'}}
                                                ref={imgUploader}
                                                accept=".jpg, .jpeg"
                                                onChange={handleChangeImg}
                                            />
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                        </div>

                        {audioFile && !soundError ? (
                            pendingSound ? (
                                <div className={classes.soundLoaderSection}>
                                    <CircularProgress style={{color: 'white'}} size={33}/>
                                </div>
                            ) : (
                                <PlayerPreview sound={soundUrl.replace('http://', 'https://')} title={audioFile.name}/>
                            )
                        ) : (
                            <>
                                <Tooltip title="Cliquez pour importer votre fichier audio au format .mp3">
                                    <div className={classes.emptyPlayer} onClick={handleClickSound}>
                                        <input
                                            type="file"
                                            style={{display: 'none'}}
                                            ref={soundFileUploader}
                                            accept=".mp3"
                                            onChange={handleChangeSound}
                                        />
                                        <IconButton>
                                            <CloudUploadRounded className={classes.cloudIcon}/>
                                        </IconButton>

                                        <span className={classes.emptyPlayerFile}>Importez un fichier mp3</span>
                                    </div>
                                </Tooltip>
                            </>
                        )}

                        <div className={classes.submitWrapper}>
                            <Button variant="contained" color="primary" onClick={onClose}>
                                Annuler
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!formComplete || soundError}
                                onClick={createStory}
                            >
                                Envoyer
                            </Button>
                        </div>

                        <div className={classes.infos}>
                            <p>
                                NB: Seuls les fichiers JPG et MP3 sont supportés. <br/>
                                Pour convertir votre fichier audio au format MP3,{' '}
                                <a href="https://audio.online-convert.com/convert-to-mp3">cliquez ici.</a> <br/>
                                Pour convertir votre image format JPG,{' '}
                                <a href="https://image.online-convert.com/fr/convertir-en-jpg">cliquez ici.</a>
                            </p>
                        </div>
                        <AlertSimple
                            title={"Erreur lors de l'envoi de votre fichier audio"}
                            description={
                                "L'envoi de votre fichier audio a échoué. Assurez-vous qu'il s'agisse bien d'un fichier .mp3 et que sa taille n'excede pas 30Mo"
                            }
                            isOpen={soundError}
                            handleAgree={closeSoundAlert}
                        />
                        <AlertSimple
                            title={"Erreur lors de l'envoi de votre fichier image"}
                            description={
                                "L'envoi de votre fichier image a échoué. Assurez-vous qu'il s'agisse bien d'un fichier jpeg et que sa taille n'excede pas 30Mo"
                            }
                            isOpen={imgError}
                            handleAgree={closeImgAlert}
                        />
                    </>
                )}
            </div>
        </Modal>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '50%',
        height: '75%',
        margin: 'auto',
    },
    contents: {
        padding: '5% 5% 1% 5%',
        background: 'white',
    },
    titleSection: {
        textAlign: 'center',
        color: '#2796A5',
    },
    formSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    inputSection: {
        width: '45%',
    },
    select: {
        width: '100%',
        marginTop: 10,
    },
    rightSection: {
        width: '45%%',
    },
    imgSection: {
        width: 220,
        height: 220,
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    imgLoader: {
        width: 200,
        height: 200,
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    imgMsg: {
        textAlign: 'center',
        background: '#2796A5',
        width: 180,
        alignSelf: 'center',
        color: 'white',
        fontWeight: 'bold',
    },
    emptyPlayer: {
        width: '100%',
        height: 60,
        background: '#2796A5',
        cursor: 'pointer',
    },
    emptyPlayerFile: {
        color: 'white',
    },
    cloudIcon: {
        color: 'white',
        height: 33,
        width: 33,
    },
    submitWrapper: {
        marginTop: 30,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
    soundLoader: {
        color: 'fff',
    },
    soundLoaderSection: {
        width: '100%',
        height: 60,
        background: '#2796A5',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    infos: {
        textAlign: 'center',
        fontSize: 12,
        marginTop: '20px',
    },
    circularProgress: {
        margin: 'auto',
        height: 450,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    switchTrack: {
        backgroundColor: theme.palette.primary.main,
    },
    switchBase: {
        color: theme.palette.primary.main,
    },
}))
