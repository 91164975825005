import { createMuiTheme } from "@material-ui/core/styles";

export let theme = createMuiTheme({
  palette: {
    primary: {
      light: "#01b4cc",
      main: "#2896a5",
      dark: "#577276",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
    },
  },
//   typography: {
//     h5: {
//       fontWeight: 500,
//       fontSize: 26,
//       letterSpacing: 0.5,
//     },
//   },
//   shape: {
//     borderRadius: 8,
//   },
//   props: {
//     MuiTab: {
//       disableRipple: true,
//     },
//   },
//   mixins: {
//     toolbar: {
//       minHeight: 48,
//     },
//   },
});

theme = {
  ...theme,
//   overrides: {
//     MuiDrawer: {
//       paper: {
//         background: theme.palette.primary.main,
//         // "radial-gradient(circle, rgba(31,75,83,1) 29%, rgba(40,150,165,1) 100%)",
//       },
//     },
//     MuiButton: {
//       label: {
//         textTransform: "none",
//       },
//       contained: {
//         boxShadow: "none",
//         "&:active": {
//           boxShadow: "none",
//         },
//       },
//     },
//     MuiTabs: {
//       root: {
//         marginLeft: theme.spacing(1),
//       },
//       indicator: {
//         height: 3,
//         borderTopLeftRadius: 3,
//         borderTopRightRadius: 3,
//         backgroundColor: theme.palette.common.white,
//       },
//     },
//     MuiTab: {
//       root: {
//         textTransform: "none",
//         margin: "0 16px",
//         minWidth: 0,
//         padding: 0,
//         [theme.breakpoints.up("md")]: {
//           padding: 0,
//           minWidth: 0,
//         },
//       },
//     },
//     MuiIconButton: {
//       root: {
//         padding: theme.spacing(1),
//       },
//     },
//     MuiTooltip: {
//       tooltip: {
//         borderRadius: 4,
//       },
//     },
//     MuiDivider: {
//       root: {
//         backgroundColor: "#404854",
//       },
//     },
//     MuiListItemText: {
//       primary: {
//         fontWeight: theme.typography.fontWeightMedium,
//       },
//     },
//     MuiListItemIcon: {
//       root: {
//         color: "inherit",
//         marginRight: 0,
//         "& svg": {
//           fontSize: 20,
//         },
//       },
//     },
//     MuiAvatar: {
//       root: {
//         width: 32,
//         height: 32,
//       },
//     },
//   },
};
