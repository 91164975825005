import React from 'react';
import {Provider, useDispatch, useSelector} from "react-redux";
import rootStore from "../stores/rootStore";
import Dashboard from "./home-page/Dashboard";
import Login from "./login/Login";
import ReactGA from 'react-ga';
import '../ressources/style/player.css'


function Security() {
    const users = useSelector(state => state.users);
    const dispatch = useDispatch();

    if (users.token === null && localStorage.getItem('bkn_token') !== null) {
        dispatch({
            type: 'SIGNIN_USER',
            payload: localStorage.getItem('bkn_token')
        });
    }

    return (
        (users.token !== null) ? <Dashboard/> : <Login />
    );
}

function App() {
    const trackingId = "UA-96200218-4";
    ReactGA.initialize(trackingId);

    return (
        <Provider store={rootStore}>
            <Security />
        </Provider>
    );
}

export default App;
