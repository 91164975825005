import React from 'react'
import { GridListTile } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import addStory from '../../ressources/images/importStory.png'

export const CreateStoryCard = ({ toggleCreationModal }) => {
    const classes = useStyles()

    return (
        <GridListTile className={classes.tile} onClick={toggleCreationModal}>
            <img src={addStory} alt="addBtn" className={classes.img} />
        </GridListTile>
    )
}

const useStyles = makeStyles((theme) => ({
    tile: {
        width: '196px',
        height: '196px',
        margin: '2px',
        cursor: 'pointer',
    },
    img: {
        height: '196px',
        width: '196px',
    },
}))
