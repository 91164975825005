
const initialState = {
    stories: [],
    publishersStories: [],
    publishersAccountStories: [],
    storiesUpdate: null,
    loading: false
};

function StoriesReducer(state = initialState, action) {
    switch (action.type) {
        case 'PUT_STORIES':
            return { ...state, storiesUpdate: action.payload };
        case 'LOADING_STORIES':
            return { ...state, loading: action.payload };
        case 'GET_STORIES':
            return { ...state, stories: action.payload };
        case 'GET_PUBLISHERS_STORIES':
            return { ...state, publishersStories: action.payload };
        case 'CLEAR_PUBLISHERS_STORIES':
            return { ...state, publishersStories: [] };
        case 'GET_PUBLISHERS_ACCOUNT_STORIES':
            return { ...state, publishersAccountStories: action.payload };
        case 'CLEAR_PUBLISHERS_ACCOUNT_STORIES':
            return { ...state, publishersAccountStories: [] };
        default:
            return state;
    }
}

export default StoriesReducer;