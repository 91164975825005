import React, { useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { useDispatch, useSelector } from 'react-redux'
import { SigninUser } from '../../stores/users/UsersAction'
import { VERSION } from '../../utils/Api/constants'

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Bookinou by PIMELY SAS
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

export default function SignIn() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const error = useSelector((state) => state.users.error)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    SigninUser()

    const handleSignIn = () => {
        let emailLower = email.toLowerCase()

        dispatch({
            type: 'INIT_SIGNIN_USER',
            payload: { email: emailLower, password: password },
        })
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Authentification
                </Typography>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Votre adresse e-mail"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={(event) => setEmail(event.target.value)}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Votre mot de passe"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={(event) => setPassword(event.target.value)}
                />
                {/*                <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                />*/}
                <Button fullWidth variant="contained" color="primary" className={classes.submit} onClick={handleSignIn}>
                    Connexion
                </Button>
                <Grid item className={classes.error}>
                    {error}
                </Grid>
                <Grid container>
                    <Grid item xs>
                        <Link href={`https://appli.mybookinou.com/v${VERSION}/password-forgot`}>
                            Mot de passe oublié ?
                        </Link>
                    </Grid>
                    {/*                    <Grid item>
                        <Link href="#" variant="body2">
                            {"Don't have an account? Sign Up"}
                        </Link>
                    </Grid>*/}
                </Grid>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    )
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#f15249',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#2896a5',
    },
    error: {
        color: 'red',
    },
}))
