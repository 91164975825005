import React from 'react'

import { Switch, Route } from 'react-router-dom'

import { StoryPublisherList } from '../components/StoryPublisherList'
import { AccountSwitch } from '../components/AccountSwitch'
import { StoryList } from '../components/StoryList'

export default function Router() {
    return (
        <Switch>
            {/* <Route path="/editors">
                <AccountSwitch />
                <StoryPublisherList type="all" />
            </Route> */}
            <Route exact path="/">
                <AccountSwitch />
                <StoryList />
                <StoryPublisherList type="account" />
            </Route>
        </Switch>
    )
}
