import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GetAccounts } from '../../stores/accounts/AccountsAction'
import { GetStories, UpdateStory } from '../../stores/stories/StoriesAction'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { GridList } from '@material-ui/core'
import CustomPaper from './CustomPaper'
import Title from './Title'
import StoryFormModal from './StoryFormModal'
import { StoryCard } from './StoryCard'
import { CreateStoryCard } from './CreateStoryCard'
import { BASE_URL } from '../../utils/Api/constants'

export function StoryList() {
    let stories = useSelector((state) => state.stories.stories)
    const user = useSelector((state) => state.users.current)
    const users = useSelector((state) => state.users)
    const classes = useStyles()
    const [formModalOpen, setFormModalOpen] = useState(false)
    const [updatedList, setUpdatedList] = useState(false)
    const token = useSelector((state) => state.users.token)
    const currentAccount = useSelector((state) => state.accounts.current)
    const [currentAccountId, setCurrentAccountId] = useState(false)
    const accounts = useSelector((state) => state.accounts.accounts)
    const currentUser = useSelector((state) => state.users.current)
    const [membershipId, setMembershipId] = useState(undefined)
    const [storiesToDisplay, setStoriesToDisplay] = useState(stories)

    // Load all accounts
    GetAccounts()
    GetStories()
    UpdateStory()

    useEffect(() => {
        if (currentAccount) {
            let currentAccountArray = currentAccount.split('/')
            setCurrentAccountId(currentAccountArray[3])
        }
    }, [currentAccount])

    const toggleCreationModal = () => {
        setFormModalOpen(!formModalOpen)
    }

    const handleUpdate = () => {
        setUpdatedList(true)
    }

    useEffect(() => {
        if (updatedList) {
            window.location.reload(false)
        }
    }, [updatedList])

    useEffect(() => {
        if (stories && currentUser && currentUser.type === 'publisher') {
            setStoriesToDisplay(stories)
        } else {
            setStoriesToDisplay(stories.filter((s) => s.node.owner.type === 'user'))
        }
    }, [stories, currentUser])

    const getMembershipId = useCallback(
        async (userId, accountId) => {
            const res = await fetch(`${BASE_URL}/api/graphql`, {
                method: 'POST',
                body: JSON.stringify({
                    query: `query getMembership { 
                    memberships (user_id:  ${userId}, account_id: ${accountId})  {
                      edges {
                        node {
                          id
                          role
                          pseudo
                          isAdmin
                          user {
                            id
                            type
                            lastName
                            firstName
                            picture {
                              contentUrl
                            }
                          }
                        }
                      }
                    }
                 }`,
                }),
                headers: new Headers({
                    Authorization: 'Bearer ' + users.token,
                    'Content-Type': 'application/json',
                }),
            }).then((response) => response.json())
            setMembershipId(res.data.memberships.edges[0].node.id)
            return res
        },
        [users.token]
    )

    useEffect(() => {
        if (currentUser && currentAccount && accounts) {
            getMembershipId(currentUser.id.split('/')[3], currentAccount.split('/')[3])
        }
    }, [currentUser, currentAccount, accounts, getMembershipId])

    return (
        <CustomPaper>
            <StoryFormModal isOpen={formModalOpen} handleModalClose={toggleCreationModal} handleUpdate={handleUpdate} />
            <div className={classes.ownerStoriesTitleSection}>
                <Title title="Vos histoires" />
            </div>

            <div className={classes.root}>
                <GridList className={classes.gridList} cols={1}>
                    <CreateStoryCard toggleCreationModal={toggleCreationModal} />
                    {storiesToDisplay.map((story) => (
                        <StoryCard
                            story={story}
                            author={user}
                            token={token}
                            isInAccount={true}
                            key={story.node.id}
                            updateList={handleUpdate}
                            currentAccountId={currentAccountId}
                            membershipId={membershipId}
                            type="user"
                        />
                    ))}
                </GridList>
            </div>
        </CustomPaper>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    eye: {
        width: 30,
        height: 30,
        position: 'absolute',
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
        width: '100%',
    },
    tile: {
        maxWidth: '200px',
        cursor: 'pointer',
    },

    publisherTitleH3: {
        color: '#5f6151',
        marginTop: 0,
        marginBottom: 5,
    },
    publisherTitleLabel: {
        color: '#fff',
        fontSize: 19,
        padding: '0 25px 0 5px',
        background: '#f15249',
    },
    title: {
        fontWeight: 600,
    },
    tooltip: {
        background: 'red',
    },
    ownerStoriesTitleSection: {
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
}))
