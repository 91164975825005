import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import {
    CssBaseline,
    Drawer,
    Box,
    AppBar,
    Toolbar,
    List,
    Typography,
    Divider,
    IconButton,
    Container,
} from '@material-ui/core'
import LinkUI from '@material-ui/core/Link'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { BrowserRouter } from 'react-router-dom'
import { mainListItems, secondaryListItems } from './ListItems'
import { Logout } from '../components/Logout'
import { useDispatch, useSelector } from 'react-redux'
import { GetUser } from '../../stores/users/UsersAction'
import { Player } from '../components/Player'
import logoTop from '../../ressources/images/logo-top.png'
import logoMenu from '../../ressources/images/logo-menu.png'
import Router from './Router'

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <LinkUI color="inherit" href="https://www.mybookinou.com">
                Bookinou by PIMELY SAS
            </LinkUI>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

export default function Dashboard() {
    const menu = useSelector((state) => state.global.menu)
    const dispatch = useDispatch()

    const classes = useStyles()
    const [open, setOpen] = React.useState(false)

    const handleDrawerOpen = () => {
        dispatch({
            type: 'TOGGLE_MENU',
        })
        document.querySelector('#logoMenu').style.display = 'block'
        setOpen(true)
    }
    const handleDrawerClose = () => {
        dispatch({
            type: 'TOGGLE_MENU',
        })
        document.querySelector('#logoMenu').style.display = 'none'
        setOpen(false)
    }

    GetUser()

    return (
        <div className={classes.root}>
            <BrowserRouter>
                <CssBaseline />
                <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            <img src={logoTop} alt="logo" style={{ height: '25px', marginTop: '10px' }} />
                        </Typography>
                        <Logout />
                    </Toolbar>
                </AppBar>
                <Drawer
                    id="leftMenu"
                    variant="permanent"
                    classes={{
                        paper: clsx(classes.drawerPaper, !menu.open && classes.drawerPaperClose),
                    }}
                    open={menu.open}
                >
                    <div className={classes.toolbarIcon}>
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <List>{mainListItems}</List>
                    <Divider />
                    <List>{secondaryListItems}</List>
                    <img src={logoMenu} id="logoMenu" className={classes.logoMenu} alt="logo" />
                </Drawer>
                <main className={classes.content}>
                    <Container maxWidth="lg" className={classes.container}>
                        <Router />
                        <Box pt={4}>
                            <Copyright />
                        </Box>
                    </Container>
                    <Player />
                </main>
            </BrowserRouter>
        </div>
    )
}

const drawerWidth = 280

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        background: '#2896a5',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        // background: 'rgb(31,75,83)',
        background: 'radial-gradient(circle, rgba(31,75,83,1) 29%, rgba(40,150,165,1) 100%)',
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
        background: '#1f4b53',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        paddingTop: 50,
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        margin: '30px 0',
    },
    fixedHeight: {
        height: 240,
    },
    logoTop: {
        width: 45,
    },
    logoMenu: {
        display: 'none',
        width: 70,
        margin: '25px auto',
    },
}))
