import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import AudioPlayer from 'react-h5-audio-player'
import { useSelector } from 'react-redux'

export function Player() {
    const player = useSelector((state) => state.global.player)
    const menu = useSelector((state) => state.global.menu)
    const classes = useStyles()

    let menuWidth = ''
    menu.open ? (menuWidth = '280px') : (menuWidth = '72px')

    return (
        <AudioPlayer
            className={classes.player}
            style={{ width: 'calc(100% - ' + menuWidth + ')' }}
            src={player.sound}
            showJumpControls={false}
            autoPlay={true}
        />
    )
}

const useStyles = makeStyles((theme) => ({
    player: {
        position: 'fixed',
        bottom: 0,
        background: '#2896a5',
    },
}))
