import {
    GetStoriesEffect,
    AddStoriesEffect,
    GetPublishersStoriesEffect,
    GetPublishersAccountStoriesEffect,
    RemoveStoriesEffect,
    PutStoriesEffect,
} from './StoriesEffect'
import { useDispatch } from 'react-redux'

export function GetStories() {
    GetStoriesEffect()
}

export function GetPublishersStories() {
    GetPublishersStoriesEffect()
}

export function GetPublishersAccountStories() {
    GetPublishersAccountStoriesEffect()
}

export function AddStories(token, dialog) {
    const dispatch = useDispatch()
    let storyURI = dialog.parameters.storyURI

    AddStoriesEffect(token, storyURI, dialog.checkbox)
    RemoveStoriesEffect(token, storyURI, dialog.checkbox)

    dispatch({
        type: 'STATUS_DIALOG',
        payload: { status: 'success' },
    })

    setTimeout(() => {
        dispatch({
            type: 'TOGGLE_DIALOG',
        })
    }, 1000)
}

export function UpdateStory(story, fields) {
    PutStoriesEffect(story, fields)
}
