import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GetAccounts } from '../../stores/accounts/AccountsAction'
import { AddStories, GetPublishersStories, GetPublishersAccountStories } from '../../stores/stories/StoriesAction'

import { GridList } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import 'react-h5-audio-player/lib/styles.css'
import CustomPaper from './CustomPaper'
import Title from './Title'
import { StoryCard } from './StoryCard'
import { BASE_URL } from '../../utils/Api/constants'

export function StoryPublisherList({ type }) {
    const publishersStories = useSelector((state) => state.stories.publishersStories)
    const publishersAccountStories = useSelector((state) => state.stories.publishersAccountStories)
    const token = useSelector((state) => state.users.token)
    const dialog = useSelector((state) => state.global.dialog)
    const classes = useStyles()
    const currentAccount = useSelector((state) => state.accounts.current)
    const [currentAccountId, setCurrentAccountId] = useState(false)
    const [updatedList, setUpdatedList] = useState(false)
    const accounts = useSelector((state) => state.accounts.accounts)
    const currentUser = useSelector((state) => state.users.current)
    const [membershipId, setMembershipId] = useState(undefined)
    const [storiesPublisher, setStoriesPublisher] = useState(false)
    const [isInAccount, setIsInAccount] = useState(undefined)
    const users = useSelector((state) => state.users)

    // Load all publisher stories
    GetAccounts()
    GetPublishersStories()
    GetPublishersAccountStories()

    useEffect(() => {
        if (type === 'all') {
            //Filter EDL Stories
            setStoriesPublisher(publishersStories.filter((p) => p.publisherID !== '/api/users/21678'))
            setIsInAccount(false)
        } else {
            setStoriesPublisher(publishersAccountStories)
            setIsInAccount(true)
        }
    }, [publishersAccountStories, publishersStories, type])

    useEffect(() => {
        if (currentAccount) {
            let currentAccountArray = currentAccount.split('/')
            setCurrentAccountId(currentAccountArray[3])
        }
    }, [currentAccount])

    const handleUpdate = () => {
        setUpdatedList(true)
    }

    useEffect(() => {
        if (updatedList) {
            window.location.reload(false)
        }
    }, [updatedList])

    if (dialog.agree) {
        AddStories(token, dialog)
    }

    const getMembershipId = useCallback(
        async (userId, accountId) => {
            const res = await fetch(`${BASE_URL}/api/graphql`, {
                method: 'POST',
                body: JSON.stringify({
                    query: `query getMembership { 
                    memberships (user_id:  ${userId}, account_id: ${accountId})  {
                      edges {
                        node {
                          id
                          role
                          pseudo
                          isAdmin
                          user {
                            id
                            type
                            lastName
                            firstName
                            picture {
                              contentUrl
                            }
                          }
                        }
                      }
                    }
                 }`,
                }),
                headers: new Headers({
                    Authorization: 'Bearer ' + users.token,
                    'Content-Type': 'application/json',
                }),
            }).then((response) => response.json())
            setMembershipId(res.data.memberships.edges[0].node.id)
            return res
        },
        [users.token]
    )

    useEffect(() => {
        if (currentUser && currentAccount && accounts) {
            getMembershipId(currentUser.id.split('/')[3], currentAccount.split('/')[3])
        }
    }, [currentUser, currentAccount, accounts, getMembershipId])

    return storiesPublisher ? (
        storiesPublisher.map((publisher) => (
            <CustomPaper key={publisher.firstName}>
                <Title title={publisher.firstName + ' ' + publisher.lastName} />

                <div className={classes.root}>
                    <GridList className={classes.gridList} cols={1}>
                        {publisher.stories.map((story) => (
                            <StoryCard
                                story={story}
                                author={publisher}
                                token={token}
                                isInAccount={isInAccount}
                                key={story.node.id}
                                updateList={handleUpdate}
                                currentAccountId={currentAccountId}
                                membershipId={membershipId}
                                type="publisher"
                            />
                        ))}
                    </GridList>
                </div>
            </CustomPaper>
        ))
    ) : (
        <></>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        width: '100%',
    },
}))
