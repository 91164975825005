import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AddStories } from '../../stores/stories/StoriesAction'
import AlertConfirmation from './AlertConfirmation'

import { GridListTile, GridListTileBar, IconButton, Tooltip } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import QueueIcon from '@material-ui/icons/Queue'
import 'react-h5-audio-player/lib/styles.css'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'
import ReactGA from 'react-ga'
import { BASE_URL } from '../../utils/Api/constants'

export const StoryCard = ({ story, author, isInAccount, token, updateList, currentAccountId, type, membershipId }) => {
    const dialog = useSelector((state) => state.global.dialog)
    const dispatch = useDispatch()
    const classes = useStyles()
    const [alertDelete, setAlertDelete] = useState(false)
    const [alertActivate, setAlertActivate] = useState(false)
    const currentUser = useSelector((state) => state.users.current)

    const loading = useSelector((state) => state.stories.loading)
    const [storyId, setStoryId] = useState(false)
    const [libraryId, setLibraryId] = useState(false)

    const toggleDeleteAlert = () => {
        if (storyId && currentAccountId && currentUser.type !== 'publisher') {
            getLibrary()
        }
        setAlertDelete(!alertDelete)
    }

    const toggleActivateAlert = () => {
        if (storyId && currentAccountId && type === 'user') {
            getLibrary()
        }
        setAlertActivate(!alertActivate)
    }

    const getLibrary = useCallback(async () => {
        const libraryRes = await fetch(`${BASE_URL}/api/graphql`, {
            method: 'POST',
            crossDomain: true,
            body: JSON.stringify({
                query: `
            query getLibrary {
              libraries (story: ${storyId}, account: ${currentAccountId}) {
                edges {
                  node {
                    id
                    isTransfered
                  }
                }
              }
            }`,
            }),
            headers: new Headers({
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
            }),
        })
            .then((res) => res.json())
            .catch((error) => console.log('library error', error))
        if (libraryRes && !libraryRes.errors) {
            setLibraryId(libraryRes.data.libraries.edges[0].node.id.split('/')[3])
        }
    }, [storyId, currentAccountId, token])

    const removeLibrary = async () => {
        if (libraryId) {
            const res = await fetch(`${BASE_URL}/api/libraries/${libraryId}`, {
                method: 'PUT',
                crossDomain: true,
                body: JSON.stringify({ isActive: false }),
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                }),
            })
                .then((response) => {
                    return response.json()
                })
                .catch((error) => {
                    console.log('removeLib error', error)
                })
            toggleDeleteAlert()
            updateList()
        }
    }

    const removeStory = async () => {
        if (storyId) {
            console.log('storyId', storyId)
            const res = await fetch(`${BASE_URL}/api/stories/${storyId}`, {
                method: 'PUT',
                crossDomain: true,
                body: JSON.stringify({ isActive: false }),
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                }),
            })
                .then((response) => {
                    return response.json()
                })
                .catch((error) => {
                    console.log('removeStory error', error)
                })
            toggleDeleteAlert()
            updateList()
        }
    }

    const postLibrary = async () => {
        if (storyId && membershipId) {
            const postRes = await fetch(`${BASE_URL}/api/libraries`, {
                method: 'POST',
                crossDomain: true,
                body: JSON.stringify({
                    story: `api/stories/${storyId}`,
                    membership: `api/memberships/${membershipId}`,
                    isTransfered: true,
                    isActive: true,
                }),
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                }),
            })
                .then((response) => {
                    return response.json()
                })
                .catch((error) => {
                    console.log('postLib error', error)
                })
            if (postRes['hydra:description'].includes('already exist')) {
                reactivateLibrary()
                toggleActivateAlert()
                // updateList()
            } else {
                toggleActivateAlert()
                // updateList()
            }
        }
    }

    const reactivateLibrary = async () => {
        const reactivateRes = await fetch(`${BASE_URL}/api/libraries/reactivate/${membershipId}/${storyId}`, {
            method: 'POST',
            crossDomain: true,
            body: JSON.stringify({}),
            headers: new Headers({
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
            }),
        })
            .then((response) => {
                return response.json()
            })
            .catch((error) => {
                return error
            })
        return reactivateRes
    }

    const loadPlayer = (id, title, soundURL) => {
        dispatch({
            type: 'LOAD_PLAYER',
            payload: soundURL,
        })
        ReactGA.event({
            category: "Listen user's story",
            action: 'User click on story for listen [' + id + ' | ' + title + ']',
        })
    }

    if (dialog.agree) {
        AddStories(token, dialog)
    }

    useEffect(() => {
        if (story) {
            let storyIdArray = story.node.id.split('/')
            setStoryId(storyIdArray[3])
        }
    }, [story])

    return (
        <>
            <AlertConfirmation
                title={'Voulez-vous supprimer cette histoire de votre bibliothèque ?'}
                description={'Cette action est irréversible'}
                isOpen={alertDelete}
                handleClose={toggleDeleteAlert}
                handleAgree={currentUser.type === 'publisher' ? removeStory : removeLibrary}
                loading={loading}
            />
            <AlertConfirmation
                title={'Voulez-vous activer cette histoire ?'}
                description={'Elle sera ensuite disponible dans votre bibliothèque'}
                isOpen={alertActivate}
                handleClose={toggleActivateAlert}
                handleAgree={postLibrary}
                loading={loading}
            />
            <GridListTile
                key={story.node.id}
                className={classes.tile}
                onClick={() => loadPlayer(story.node.id, story.node.title, story.node.sound?.contentUrl)}
            >
                <img src={story.node.picture.contentUrl} alt={story.node.title} />
                {!isInAccount ? (
                    <GridListTileBar
                        title={<label className={classes.title}>{story.node.title}</label>}
                        subtitle={
                            <span>
                                par: {author.firstName} {author.lastName}
                            </span>
                        }
                        actionIcon={
                            <Tooltip title="Ajouter l'histoire à votre bibliothèque" placement="bottom">
                                <IconButton
                                    aria-label={`Ajouter cette histoire à votre compte`}
                                    className={classes.icon}
                                    onClick={toggleActivateAlert}
                                >
                                    <QueueIcon style={{ fill: 'white' }} />
                                </IconButton>
                            </Tooltip>
                        }
                    />
                ) : (
                    <GridListTileBar
                        title={<label className={classes.title}>{story.node.title}</label>}
                        subtitle={
                            <span>
                                par: {author.firstName} {author.lastName}
                            </span>
                        }
                        actionIcon={
                            <Tooltip title="Supprimer l'histoire de votre bibliothèque" placement="bottom">
                                <IconButton
                                    aria-label={`Retirer cette histoire de votre compte`}
                                    className={classes.icon}
                                    onClick={toggleDeleteAlert}
                                >
                                    <IndeterminateCheckBoxIcon style={{ fill: 'white' }} />
                                </IconButton>
                            </Tooltip>
                        }
                    />
                )}
            </GridListTile>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        width: '100%',
    },
    accountTitle: {
        width: '100%',
        textAlign: 'left',
        marginTop: 0,
    },
    preTitle: {
        width: '100%',
        textAlign: 'left',
        fontSize: '11px',
    },
    tile: {
        width: '196px',
        height: '196px',
        margin: '2px',
        cursor: 'pointer',
    },
}))
