import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactGA from 'react-ga'
import { BASE_URL } from '../../utils/Api/constants'

export function SigninUserEffect() {
    const dispatch = useDispatch()
    const init = useSelector((state) => state.users.init)

    useEffect(() => {
        if (init !== null) {
            fetch(`${BASE_URL}/api/users/login`, {
                method: 'POST',
                body: JSON.stringify({ username: init.email, password: init.password }),
                headers: new Headers({
                    'Content-Type': 'application/json',
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Login error')
                    } else {
                        return response.json()
                    }
                })
                .then((response) => {
                    let token = response['hydra:description']
                    localStorage.setItem('bkn_token', token)
                    dispatch({
                        type: 'SIGNIN_USER',
                        payload: token,
                    })
                })
                .catch((response) => {
                    dispatch({
                        type: 'ADD_ERROR_USER',
                        payload: 'Votre e-mail ou mot de passe sont invalides.',
                    })
                })
        }
    }, [init])
}

export function GetUserEffect() {
    const dispatch = useDispatch()
    const token = useSelector((state) => state.users.token)
    const isLogged = useSelector((state) => state.users.isLogged)

    useEffect(() => {
        if (token !== null && isLogged === true) {
            fetch(`${BASE_URL}/api/users/me`, {
                method: 'GET',
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Login error')
                    } else {
                        return response.json()
                    }
                })
                .then((response) => {
                    dispatch({
                        type: 'GET_USER',
                        payload: {
                            id: response['@id'],
                            username: response['username'],
                            firstName: response['firstName'],
                            lastName: response['lastName'],
                            type: response['type'],
                        },
                    })

                    ReactGA.set({
                        id: response['@id'],
                        username: response['username'],
                        firstName: response['firstName'],
                        lastName: response['lastName'],
                    })
                })
                .catch((response) => {
                    localStorage.removeItem('bkn_token')
                    dispatch({
                        type: 'LOGOUT_USER',
                    })
                    window.location.reload()
                })
        }
    }, [token])
}

export function GetPublishersEffect() {
    const dispatch = useDispatch()
    const token = useSelector((state) => state.users.token)

    useEffect(() => {
        if (token !== null) {
            fetch(`${BASE_URL}/api/graphql`, {
                method: 'POST',
                body: JSON.stringify({
                    query: '{ users (type: "publisher", order: {updatedAt: "DESC"}) { edges { node { id firstName lastName } } } }',
                }),
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                }),
            })
                .then((response) => response.json())
                .then((response) => {
                    dispatch({
                        type: 'GET_PUBLISHERS',
                        payload: response.data.users.edges,
                        isLogged: true,
                    })
                })
                .catch((response) => {
                    alert()
                })
        }
    }, [token])
}
