import React from 'react'
import {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Dialog,
    Button,
    CircularProgress,
} from '@material-ui/core'

export default function AlertSimple(props) {
    return (
        <Dialog
            open={props.isOpen}
            onClose={props.handleClose}
            aria-labelledby={props.title}
            aria-describedby={props.description}
        >
            <DialogTitle id="alert-dialog-title">{props.title && props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.description && props.description}
                </DialogContentText>
            </DialogContent>
            {props.loading ? (
                <DialogActions>
                    <CircularProgress />
                </DialogActions>
            ) : (
                <DialogActions>
                    <Button onClick={props.handleAgree} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    )
}
