import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BASE_URL } from '../../utils/Api/constants'

export function GetAccountsEffect() {
    const dispatch = useDispatch()
    const users = useSelector((state) => state.users)

    useEffect(() => {
        if (users !== null && users.token !== null) {
            fetch(`${BASE_URL}/api/graphql`, {
                method: 'POST',
                body: JSON.stringify({
                    query: '{ accounts { edges { node { id name picture { contentUrl } memberships { edges { node { id user { id } isAdmin } } } } } } }',
                }),
                headers: new Headers({
                    Authorization: 'Bearer ' + users.token,
                    'Content-Type': 'application/json',
                }),
            })
                .then((response) => response.json())
                .then((response) => {
                    dispatch({
                        type: 'GET_ACCOUNTS',
                        payload: response.data.accounts.edges,
                    })
                })
        }
    }, [users, dispatch])
}
