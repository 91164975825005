import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'
import { Link } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'

export const mainListItems = (
    <div>
        <Link to="/" style={{ color: '#fff', textDecoration: 'none' }}>
            <Tooltip title="Bibliothèque Bookinou" placement="right">
                <ListItem button>
                    <ListItemIcon>
                        <LibraryBooksIcon style={{ color: '#fff' }} />
                    </ListItemIcon>
                    <ListItemText primary="Bibliothèque Bookinou" />
                </ListItem>
            </Tooltip>
        </Link>
        {/* <Link to="/editors" style={{ color: '#fff', textDecoration: 'none' }}>
            <Tooltip title="Les éditeurs" placement="right">
                <ListItem button>
                    <ListItemIcon>
                        <LocalLibraryIcon style={{ color: '#fff' }} />
                    </ListItemIcon>
                    <ListItemText primary="Les éditeurs" />
                </ListItem>
            </Tooltip>
        </Link> */}
    </div>
)

export const secondaryListItems = (
    <div>
        <ListSubheader inset style={{ color: '#fff' }}>
            -- LA BOUTIQUE --
        </ListSubheader>

        <Tooltip title="Les gommettes" placement="right">
            <ListItem
                button
                onClick={() =>
                    window.open('https://www.mybookinou.com/boutique/22-pack-gommettes-3770013172052.html', '_blank')
                }
            >
                <ListItemIcon>
                    <ShoppingCartIcon style={{ color: '#fff' }} />
                </ListItemIcon>
                <ListItemText primary="Les gommettes" style={{ color: '#fff' }} />
            </ListItem>
        </Tooltip>

        <Tooltip title="Le Bookinou" placement="right">
            <ListItem
                button
                onClick={() =>
                    window.open('https://www.mybookinou.com/accueil/20-bookinou-3770013172007.html', '_blank')
                }
            >
                <ListItemIcon>
                    <ShoppingCartIcon style={{ color: '#fff' }} />
                </ListItemIcon>
                <ListItemText primary="Le Bookinou" style={{ color: '#fff' }} />
            </ListItem>
        </Tooltip>

        <Tooltip title="Carte de voyage" placement="right">
            <ListItem
                button
                onClick={() =>
                    window.open('https://www.mybookinou.com/boutique/34-carte-de-voyage-3770013172038.html', '_blank')
                }
            >
                <ListItemIcon>
                    <ShoppingCartIcon style={{ color: '#fff' }} />
                </ListItemIcon>
                <ListItemText primary="Carte de voyage" style={{ color: '#fff' }} />
            </ListItem>
        </Tooltip>

        <Tooltip title="Aide et support" placement="right">
            <ListItem button onClick={() => window.open('https://support.mybookinou.com/hc/fr', '_blank')}>
                <ListItemIcon>
                    <ContactSupportIcon style={{ color: '#fff' }} />
                </ListItemIcon>
                <ListItemText primary="Aide et support" style={{ color: '#fff' }} />
            </ListItem>
        </Tooltip>
    </div>
)
