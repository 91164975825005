
import { combineReducers } from "redux";
import StoriesReducer from "../stores/stories/StoriesReducer";
import AccountsReducer from "../stores/accounts/AccountsReducer";
import UsersReducer from "./users/UsersReducer";
import PodiumReducer from "./podium/PodiumReducer";

const initialState = {
    dialog: {
        open: false,
        agree: null,
        status: 'init',
        checkbox: [],
        already_checked: [],
        parameters: {},
    },
    menu: {
        open: false,
    },
    player: {
        sound: null,
        play: false,
    }
};

function GlobalReducer(state = initialState, action) {
    switch (action.type) {
        case 'TOGGLE_DIALOG':
            if (state.dialog.open) return { ...initialState };
            state.dialog.open = !state.dialog.open;
            return { ...state, dialog: {...state.dialog} };
        case 'AGREE_DIALOG':
            state.dialog.agree = true;
            return { ...state, dialog: {...state.dialog} };
        case 'ADD_BODY_DIALOG':
            state.dialog.checkbox = action.payload;
            return { ...state, dialog: {...state.dialog} };
        case 'ALREADY_CHECKED_DIALOG':
            state.dialog.already_checked = action.payload;
            return { ...state, dialog: {...state.dialog} };
        case 'DISAGREE_DIALOG':
            state.dialog.open = false;
            state.dialog.agree = false;
            return { ...state, dialog: {...state.dialog} };
        case 'ADD_PARAMETERS_DIALOG':
            let parameters = action.payload;
            Object.keys(parameters).map(key => {
                state.dialog.parameters[key] = parameters[key];
            });
            return { ...state, dialog: {...state.dialog} };
        case 'STATUS_DIALOG':
            state.dialog.agree = action.payload.agree;
            state.dialog.status = action.payload.status;
            return { ...state, dialog: {...state.dialog} };
        case 'CLEAR_DIALOG':
            return { ...state, dialog: initialState };
        case 'TOGGLE_MENU':
            state.menu.open = !state.menu.open;
            return { ...state, menu: {...state.menu} };
        case 'LOAD_PLAYER':
            state.player.sound = action.payload;
            state.player.play = true;
            return { ...state, player: {...state.player} };
        case 'PLAY_PLAYER':
            state.player.play = true;
            return { ...state, player: {...state.player} };
        case 'STOP_PLAYER':
            state.player.play = false;
            return { ...state, player: {...state.player} };
        default:
            return state;
    }
}

export default combineReducers({
    stories: StoriesReducer,
    accounts: AccountsReducer,
    users: UsersReducer,
    global: GlobalReducer,
    podium: PodiumReducer
});