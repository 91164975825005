
const initialState = {
    token: null,
    current: null,
    publishers: [],
    isLogged: false,
    init: null,
    error: null,
};

function UsersReducer(state = initialState, action) {
    switch (action.type) {
        case 'SIGNIN_USER':
            return { ...state, token: action.payload, isLogged: true };
        case 'LOGOUT_USER':
            return { ...initialState };
        case 'INIT_SIGNIN_USER':
            return { ...state, init: action.payload };
        case 'GET_USER':
            return { ...state, current: action.payload };
        case 'ADD_ERROR_USER':
            return { ...state, error: action.payload };
        case 'GET_PUBLISHERS':
            return { ...state, publishers: action.payload };
        default:
            return state;
    }
}

export default UsersReducer;