import React, {useRef} from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'

export function PlayerPreview({ sound, title }) {
    const classes = useStyles()
    const player = useRef(null)

    return (
        <AudioPlayer
            className={classes.player}
            ref={player}
            src={sound}
            showJumpControls={false}
            showSkipControls={false}
            defaultDuration="00:00"
            defaultCurrentTime="00:00"
            layout={'horizontal-reverse'}
            customAdditionalControls={[]}
            customVolumeControls={[]}
            customControlsSection={[RHAP_UI.MAIN_CONTROLS, <span className={classes.title}>{title}</span>, <div></div>]}
            customProgressBarSection={[RHAP_UI.CURRENT_TIME, RHAP_UI.PROGRESS_BAR, RHAP_UI.DURATION]}
        />
    )
}

const useStyles = makeStyles((theme) => ({
    player: {
        background: '#2896a5',
        height: 60,
    },
    title: {
        color: 'white',
    },
}))
