import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import getLastPart from '../../helpers/getLastPart.js'
import { BASE_URL } from '../../utils/Api/constants'

export function GetStoriesEffect() {
    const dispatch = useDispatch()
    const token = useSelector((state) => state.users.token)
    const currentAccount = useSelector((state) => state.accounts.current)
    const user = useSelector((state) => state.users.current)

    useEffect(() => {
        //PATCH : SPECIAL GET STORIES FOR IAP TEST ACCOUNT
        if (user && user.type === 'publisher') {
            const userId = getLastPart(user.id)
            fetch(`${BASE_URL}/api/graphql`, {
                method: 'POST',
                body: JSON.stringify({
                    query: `{ stories (publisher:${userId}, order: {updatedAt: "DESC"}) { edges { node { id title picture { contentUrl } sound { contentUrl } role pseudo owner { type id } } }  pageInfo{ hasNextPage endCursor } totalCount } }`,
                }),
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                }),
            })
                .then((response) => response.json())
                .then((response) => {
                    dispatch({
                        type: 'GET_STORIES',
                        payload: response.data.stories.edges,
                    })
                })
        } else if (currentAccount !== null) {
            const currentAccountId = currentAccount.split('/')

            fetch(`${BASE_URL}/api/graphql`, {
                method: 'POST',
                body: JSON.stringify({
                    query:
                        '{ stories (account: ' +
                        currentAccountId[3] +
                        ', order: {updatedAt: "DESC"}) { edges { node { id title picture { contentUrl } sound { contentUrl } role pseudo owner { type id } } }  pageInfo{ hasNextPage endCursor } totalCount } }',
                }),
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                }),
            })
                .then((response) => response.json())
                .then((response) => {
                    dispatch({
                        type: 'GET_STORIES',
                        payload: response.data.stories.edges,
                    })
                })
        }
    }, [currentAccount, dispatch, token])
}

export function PutStoriesEffect() {
    const dispatch = useDispatch()
    const token = useSelector((state) => state.users.token)
    const updateStory = useSelector((state) => state.stories.storiesUpdate)

    useEffect(() => {
        if (updateStory) {
            const response = updateStory
            // fetch('https://api-v2.7.mybookinou.com/stories' + story, {
            //     method: 'PUT',
            //     body: JSON.stringify(fields),
            //     headers: new Headers({
            //         'Authorization': 'Bearer ' + token,
            //         'Content-Type': 'application/json'
            //     })
            // })
            // .then(response => response.json())
            // .then(response => {
            dispatch({
                type: 'LOADING_STORIES',
                payload: false,
            })
            console.log(response)
        }
    }, [dispatch, updateStory])
}

export function GetPublishersStoriesEffect() {
    const token = useSelector((state) => state.users.token)
    const dispatch = useDispatch()

    useEffect(() => {
        if (token !== null) {
            GetPublishersStories('all', dispatch, token, null)
        }
    }, [dispatch, token])
}

export function GetPublishersAccountStoriesEffect() {
    const currentAccount = useSelector((state) => state.accounts.current)
    const token = useSelector((state) => state.users.token)
    const dispatch = useDispatch()

    useEffect(() => {
        if (token !== null && currentAccount !== null) {
            GetPublishersStories('account', dispatch, token, currentAccount)
        }
    }, [token, currentAccount, dispatch])
}

function GetPublishersStories(type, dispatch, token, currentAccount) {
    fetch(`${BASE_URL}/api/graphql`, {
        method: 'POST',
        body: JSON.stringify({
            query: '{ users (type: "publisher", order: {updatedAt: "DESC"}) { edges { node { id firstName lastName } } } }',
        }),
        headers: new Headers({
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
        }),
    })
        .then((response) => response.json())
        .then((response) => {
            const publishers = response.data.users.edges
            let promiseArray = []
            let publishersList = []

            publishers.map((publisher) => {
                const publisherID = publisher.node.id.split('/')

                let query = ''
                // eslint-disable-next-line default-case
                switch (type) {
                    case 'all':
                        query =
                            '{ stories (publisher: ' +
                            publisherID[3] +
                            ', order: {updatedAt: "DESC"}) { edges { node { id title picture { contentUrl } inPacks { edges {node { id } } } sound { contentUrl } owner { id } } }  pageInfo{ hasNextPage endCursor } totalCount } }'
                        break
                    case 'account':
                        let currentAccountID = currentAccount.split('/')
                        query =
                            '{ stories (publisher: ' +
                            publisherID[3] +
                            ', account_p: ' +
                            currentAccountID[3] +
                            ', order: {updatedAt: "DESC"}) { edges { node { id title picture { contentUrl } sound { contentUrl } owner { id } } }  pageInfo{ hasNextPage endCursor } totalCount } }'
                        break
                }

                promiseArray.push(
                    fetch(`${BASE_URL}/api/graphql`, {
                        method: 'POST',
                        body: JSON.stringify({ query: query }),
                        headers: new Headers({
                            Authorization: 'Bearer ' + token,
                            'Content-Type': 'application/json',
                        }),
                    })
                        .then((response) => response.json())
                        .then((response) => {
                            let publisherURI = publisher.node.id

                            if (response.data.stories.edges.length > 0) {
                                publishersList.push({
                                    publisherID: publisherURI,
                                    firstName: publisher.node.firstName,
                                    lastName: publisher.node.lastName,
                                    stories: response.data.stories.edges,
                                })
                            }
                        })
                )
            })
            Promise.all(promiseArray).then(() => {
                // eslint-disable-next-line default-case
                switch (type) {
                    case 'all':
                        dispatch({
                            type: 'GET_PUBLISHERS_STORIES',
                            payload: publishersList,
                        })
                        break
                    case 'account':
                        dispatch({
                            type: 'GET_PUBLISHERS_ACCOUNT_STORIES',
                            payload: publishersList,
                        })
                        break
                }
            })
        })
}

export function AddStoriesEffect(token, storyURI, checkbox) {
    const dispatch = useDispatch()
    let checkedBox = checkbox.filter((checkbox) => checkbox.checked)

    if (storyURI !== null && checkedBox !== null) {
        checkedBox.map((box) => {
            fetch(`${BASE_URL}/api/libraries`, {
                method: 'POST',
                body: JSON.stringify({ story: storyURI, membership: box.membershipURI }),
                headers: new Headers({
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Library already exist')
                    } else {
                        return response.json()
                    }
                })
                .catch((response) => {
                    let storyID = storyURI.split('/')
                    let membershipID = box.membershipURI.split('/')

                    fetch(`${BASE_URL}/api/libraries/reactivate/` + membershipID[3] + '/' + storyID[3], {
                        method: 'POST',
                        body: JSON.stringify({}),
                        headers: new Headers({
                            Authorization: 'Bearer ' + token,
                            'Content-Type': 'application/json',
                        }),
                    })
                })
        })
    }
}

export function RemoveStoriesEffect(token, storyURI, checkbox) {
    const dispatch = useDispatch()
    let uncheckedBox = checkbox.filter((checkbox) => !checkbox.checked)
    const storyID = storyURI.split('/')

    // Get all account already link to story
    fetch(`${BASE_URL}/api/graphql`, {
        method: 'POST',
        body: JSON.stringify({ query: '{ accounts (story: ' + storyID[3] + ') { edges { node { id } } } }' }),
        headers: new Headers({
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
        }),
    })
        .then((response) => response.json())
        .then((response) => {
            // Check if one or more accounts are unlink
            uncheckedBox.map((checkbox) => {
                let accounts = response.data.accounts.edges
                accounts.map((account) => {
                    if (account.node.id === checkbox.accountURI) {
                        // Get library to disabled
                        const accountID = account.node.id.split('/')
                        fetch(`${BASE_URL}/api/graphql`, {
                            method: 'POST',
                            body: JSON.stringify({
                                query:
                                    '{ libraries (story: ' +
                                    storyID[3] +
                                    ', account: ' +
                                    accountID[3] +
                                    ') { edges { node { id } } } }',
                            }),
                            headers: new Headers({
                                Authorization: 'Bearer ' + token,
                                'Content-Type': 'application/json',
                            }),
                        })
                            .then((response) => response.json())
                            .then((response) => {
                                let libraryURI = response.data.libraries.edges[0].node.id

                                fetch(BASE_URL + libraryURI, {
                                    method: 'PUT',
                                    body: JSON.stringify({ isActive: false }),
                                    headers: new Headers({
                                        Authorization: 'Bearer ' + token,
                                        'Content-Type': 'application/json',
                                    }),
                                })
                                    .then((response) => response.json())
                                    .then((response) => {})
                            })
                    }
                })
            })
        })
}
