const initialState = {
    podium: [],
    error: null,
}

function PodiumReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_PODIUM_DATA':
            return { ...state, podium: action.payload, error: null }
        case 'ERROR':
            return { ...state, error: action.payload }
        default:
            return state
    }
}

export default PodiumReducer
