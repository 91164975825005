import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

export default function Title(props) {
    const classes = useStyles()
    if (props.version2) {
        return (
            <h3 className={classes.publisherTitleH32}>
                <label className={classes.publisherTitleLabel2}>{props.title}</label>
            </h3>
        )
    }
    return (
        <h3 className={classes.publisherTitleH3}>
            <label className={classes.publisherTitleLabel}>{props.title}</label>
        </h3>
    )
}

const useStyles = makeStyles((theme) => ({
    txt: {
        textAlign: 'left',
        alignSelf: 'left',
        color: theme.palette.grey,
    },
    publisherTitleH3: {
        color: '#5f6151',
        marginTop: 0,
        marginBottom: 10,
    },
    publisherTitleH32: {
        color: '#5f6151',
        marginTop: 0,
        marginBottom: 10,
    },
    publisherTitleLabel: {
        color: '#fff',
        fontSize: 19,
        padding: '0 25px 0 5px',
        background: '#f15249',
    },
    publisherTitleLabel2: {
        color: '#fff',
        fontSize: 25,
        padding: '0 25px 0 5px',
        background: '#f15249',
    },
    title: {
        fontWeight: 600,
    },
}))