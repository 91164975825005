const initialState = {
    accounts: [],
    current: null,
};

function StoriesReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_ACCOUNTS':
            state.current === null && (state.current = action.payload[0].node.id);
            return { ...state, accounts: action.payload };
        case 'CHANGE_CURRENT_ACCOUNT':
            return { ...state, current: action.payload };
        default:
            return state;
    }
}

export default StoriesReducer;