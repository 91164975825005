import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import makeStyles from '@material-ui/core/styles/makeStyles'

export function AccountSwitch() {
    const accounts = useSelector((state) => state.accounts.accounts)
    const currentAccount = useSelector((state) => state.accounts.current)
    const dispatch = useDispatch()

    const classes = useStyles()

    let menuItems = accounts.map(function (account) {
        return (
            <MenuItem value={account.node.id} key={account.node.id}>
                {account.node.name}
            </MenuItem>
        )
    })

    const handleChange = (event) => {
        dispatch({
            type: 'CHANGE_CURRENT_ACCOUNT',
            payload: event.target.value,
        })
    }

    return (
        <div className={classes.rootDiv}>
            <FormControl className={classes.formControl}>
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                    Histoires du compte
                </InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    className={classes.select}
                    value={currentAccount}
                    onChange={handleChange}
                >
                    {menuItems}
                </Select>
            </FormControl>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    rootDiv: {
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    select: {
        fontSize: 22,
        fontWeight: '600',
        color: '#333333',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}))
