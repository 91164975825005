
import {GetPublishersEffect, GetUserEffect, SigninUserEffect} from "./UsersEffect";

export function SigninUser() {
    SigninUserEffect();
}

export function GetUser() {
    GetUserEffect();
}

export function GetPublishers() {
    GetPublishersEffect();
}