import React from 'react'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'
import { theme } from '../../ressources/style/theme'

export default function CustomPaper(props) {
    const classes = useStyles()

    return (
        <ThemeProvider theme={theme}>
            <Paper className={classes.paper}>{props.children && props.children}</Paper>
        </ThemeProvider>
    )
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        margin: '30px 0',
    },
}))
